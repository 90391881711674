$urlPrefix: 'https://sportngin-snap-production.s3.amazonaws.com/ui_themes/assets/latest/fonts/';
$forza: 'Forza';
$open-sans: 'Open Sans';
$light: 200;
$normal: 400;
$semi-bold: 600;
$bold: 700;


$se-fonts: (
  (family: $forza, style: normal, weight: $light, src: url('#{$urlPrefix}Forza-Thin.woff') format('woff')),
  (family: $forza, style: normal, weight: $normal, src: url('#{$urlPrefix}Forza-Light.woff') format('woff')),
  (family: $forza, style: normal, weight: $semi-bold, src: url('#{$urlPrefix}Forza-Medium.woff') format('woff')),
  (family: $forza, style: normal, weight: $bold, src: url('#{$urlPrefix}Forza-Bold.woff') format('woff')),
  (family: $open-sans, style: normal, weight: $light, src: url('#{$urlPrefix}OpenSans-Light.woff') format('woff')),
  (family: $open-sans, style: italic, weight: $light, src: url('#{$urlPrefix}OpenSans-LightItalic.woff') format('woff')),
  (family: $open-sans, style: normal, weight: $normal, src: url('#{$urlPrefix}OpenSans-Regular.woff') format('woff')),
  (family: $open-sans, style: italic, weight: $normal, src: url('#{$urlPrefix}OpenSans-Italic.woff') format('woff')),
  (family: $open-sans, style: normal, weight: $semi-bold, src: url('#{$urlPrefix}OpenSans-SemiBold.woff') format('woff')),
  (family: $open-sans, style: italic, weight: $semi-bold, src: url('#{$urlPrefix}OpenSans-SemiboldItalic.woff') format('woff')),

);
@each $font in $se-fonts {
  @font-face {
    font-family: map-get($font, family);
    font-style: map-get($font, style);
    font-weight: map-get($font, weight);
    src: map-get($font, src);
  }
}