/* You can add global styles to this file, and also import other style files */
@import "../node_modules/@angular/material/prebuilt-themes/deeppurple-amber.css";
@import "./se-ui-components-shim.variables";
@import "./se-ui-components-fonts-shim";

.mat-calendar-body-selected {
  background-color: $se-primary !important;
}

.cdk-overlay-container {
  z-index: 9999 !important;
}

.mat-datepicker-content .mat-calendar { 
  zoom: 0.85;
}
